// 菜单8我们的故事
<template>
  <div class="main">
    <div class="top">
      <div class="top-title">Our Story</div>
      <div class="top-text">
        The ALY HOME family is very diverse, including buyers, marketers, and
        customer service personnel. Behind the scenes, we have a team of 200
        highly skilled experts, including carpenters and mechanics. We focus on
        support, training, and personal development to ensure that all employees
        are important experts in their respective fields.
      </div>
    </div>
    <div class="content">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">What Makes Us Different</div>
      </div>
      <div class="content-text">
        From our humble beginnings, we have been working hard to establish
        ourselves as market leaders. All of our products are tailor-made in
        Ningbo, China and manufactured in our factory by our trusted team of
        experts.
      </div>
      <div class="content-our">
        <div class="pdf-btn" @click="toDownloadPdf()">
          DOWNLOAD OUR STORY PDF <span class="swiper-text">></span>
        </div>
      </div>
    </div>
    <div class="store-item store-bgc1">
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_from@2x.png"
      />
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">Where We Came From</div>
        </div>
        <div class="content-text">
          We have made significant progress in over 20 years, from a small room
          to a 700000 square foot factory. Although our business has expanded,
          we remain true to our roots and have been producing locally in Ningbo,
          China, trading with all customers in the European and American
          markets.
        </div>
      </div>
    </div>
    <div class="store-item store-bgc2">
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">Who Are We</div>
        </div>
        <div class="content-text">
          Our people are the key to our success. The team is vast and varied,
          including machinists, wood crafters, buyers and Customer Service
          personnel. Our is warm and welcoming, and this commitment extends to
          our trade clients.
        </div>
      </div>
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_us@2x.png"
      />
    </div>
    <div class="store-item store-bgc1">
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_you@2x.png"
      />
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">Who You Are</div>
        </div>
        <div class="content-text">
          Our trade clients are our primary focus. We understand that all
          customers are different, with different visions, budgets and
          requirements. As such, you'll be dealt with on an individual basis,
          with our dedicated Trade experts offering a personalised service.
        </div>
      </div>
    </div>
    <div class="store-item store-bgc2">
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">What We Do</div>
        </div>
        <div class="content-text">
          We offer a complete range of indoor furniture, handmade in Ningbo,
          China. This includes vertical blinds, roller blinds, and wooden
          blinds, as well as our different types of Roman blinds and curtains.
        </div>
      </div>
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_do@2x.png"
      />
    </div>
    <div class="store-item store-bgc1">
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_pos@2x.png"
      />
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">What We Stand For</div>
        </div>
        <div class="content-text">
          At ALY HOME, we focus on trust, efficiency and quality. We guarantee
          our clients a fast turnaround on a top quality product, while our
          online portal makes ordering as simple as possible.
        </div>
        <div class="content-icons">
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/from@2x.png"
            class="content-icon"
          />
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/single@2x.png"
            class="content-icon"
          />
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fast@2x.png"
            class="content-icon"
          />
          <img
            src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/year@2x.png"
            class="content-icon"
          />
        </div>
      </div>
    </div>
    <div class="store-item store-bgc2">
      <div class="store-text-part">
        <div class="content-top">
          <div class="content-liney"></div>
          <div class="content-title">Where It All Happens</div>
        </div>
        <div class="content-text">
          Our home consists of some large facilities located in Ningbo, China.
          Here, we manufacture and send all orders. We like our factory
          location, combined with stylish and modern workspaces and the latest
          technology.
        </div>
      </div>
      <img
        class="store-img-part"
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_loc@2x.png"
      />
    </div>
    <div class="content-copy" style="background-color: #caa68a">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">Our Services</div>
      </div>
      <div class="content-text">
        Quality customer service is of paramount importance to us. Our online
        portal makes ordering as simple as possible, while our customer service
        specialists are always on hand to help. Our samples department also
        plays a key role, offering customers a fast, free sampling service.
        Finally, we only partner with trusted couriers, ensuring your products
        are delivered safely and efficiently.
      </div>
      <div class="pro-content-imgs">
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/from@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/single@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fast@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/year@2x.png"
          class="pro-content-img"
        />
      </div>
    </div>
    <div class="content-copy">
      <div class="content-top">
        <div class="content-liney"></div>
        <div class="content-title">Our Responsibility</div>
      </div>
      <div class="content-text">
        ALY HOME is an independent company that cares. Our wooden blinds are
        made from 100% real wood, harvested from forests that are responsibly
        managed for sustainability. We are also dedicated to practising
        environmentally-friendly manufacturing techniques, as well as recycling,
        reusing and donating where possible.
      </div>
    </div>
  </div>
</template>
<script>
import { usVisitLogInsert } from "@/api/home.js";
export default {
  name: "OurStory",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  activated() {
    // console.log("activated");
    this.addVisitLogInsert();
  },
  methods: {
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "OurStory";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
    toDownloadPdf() {
      // console.log("toDownloadPdf");
      window.open("https://online.fliphtml5.com/hbeyc/ypmm/index.html");
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1920px;
  height: 680px;
  margin: 0 0 58px;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/hl_bg_tra@2x.png");
  background-size: cover;
  position: relative;
}
.top-title {
  font-weight: bold;
  font-size: 34px;
  color: #fff;
  position: absolute;
  top: 124px;
  left: 405px;
}
.top-text {
  width: 1100px;
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 182px;
  left: 405px;
}
.content {
  width: 1110px;
  margin: 52px auto 26px;
}
.content-copy {
  width: 1920px;
  padding: 41px 405px;
  box-sizing: border-box;
}
.pro-content-imgs {
  display: flex;
}
.pro-content-img {
  width: 128px;
  height: 64px;
  margin-right: 18px;
}
.content-liney {
  width: 1px;
  height: 60px;
  background-color: #293744;
}

.content-top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 38px;
}
.content-title {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin-left: 16px;
}
.content-text {
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 57px;
}
.content-our {
  width: 100%;
  height: 226px;
  position: relative;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_samp@2x.png");
  background-size: cover;
}
.pdf-btn {
  display: inline-block;
  padding: 16px 68px;
  border: 1px solid #fff;
  font-size: 16px;
  color: #fff;
  margin: 0 0 0 18px;
  cursor: pointer;
  position: absolute;
  top: 89px;
  left: 87px;
}
.pdf-btn:hover {
  background-color: #5f6467;
  color: #fff;
  border: 1px solid #5f6467;
}
.store-item {
  width: 1920px;
  padding: 52px 405px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.store-bgc1 {
  background-color: #f5f5f5;
}
.store-bgc2 {
  background-color: #fff;
}
.store-img-part {
  width: 445px;
  height: 445px;
}
.store-text-part {
  width: calc(100% - 476px);
}
.content-icons {
  display: flex;
  margin-top: -20px;
}
.content-icon {
  width: 128px;
  height: 64px;
  margin-right: 18px;
}
</style>
