var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_c('div',{staticClass:"content-text"},[_vm._v(" From our humble beginnings, we have been working hard to establish ourselves as market leaders. All of our products are tailor-made in Ningbo, China and manufactured in our factory by our trusted team of experts. ")]),_c('div',{staticClass:"content-our"},[_c('div',{staticClass:"pdf-btn",on:{"click":function($event){return _vm.toDownloadPdf()}}},[_vm._v(" DOWNLOAD OUR STORY PDF "),_c('span',{staticClass:"swiper-text"},[_vm._v(">")])])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"top-title"},[_vm._v("Our Story")]),_c('div',{staticClass:"top-text"},[_vm._v(" The ALY HOME family is very diverse, including buyers, marketers, and customer service personnel. Behind the scenes, we have a team of 200 highly skilled experts, including carpenters and mechanics. We focus on support, training, and personal development to ensure that all employees are important experts in their respective fields. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("What Makes Us Different")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc1"},[_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_from@2x.png"}}),_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Where We Came From")])]),_c('div',{staticClass:"content-text"},[_vm._v(" We have made significant progress in over 20 years, from a small room to a 700000 square foot factory. Although our business has expanded, we remain true to our roots and have been producing locally in Ningbo, China, trading with all customers in the European and American markets. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc2"},[_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Who Are We")])]),_c('div',{staticClass:"content-text"},[_vm._v(" Our people are the key to our success. The team is vast and varied, including machinists, wood crafters, buyers and Customer Service personnel. Our is warm and welcoming, and this commitment extends to our trade clients. ")])]),_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_us@2x.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc1"},[_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_you@2x.png"}}),_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Who You Are")])]),_c('div',{staticClass:"content-text"},[_vm._v(" Our trade clients are our primary focus. We understand that all customers are different, with different visions, budgets and requirements. As such, you'll be dealt with on an individual basis, with our dedicated Trade experts offering a personalised service. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc2"},[_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("What We Do")])]),_c('div',{staticClass:"content-text"},[_vm._v(" We offer a complete range of indoor furniture, handmade in Ningbo, China. This includes vertical blinds, roller blinds, and wooden blinds, as well as our different types of Roman blinds and curtains. ")])]),_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_do@2x.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc1"},[_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_pos@2x.png"}}),_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("What We Stand For")])]),_c('div',{staticClass:"content-text"},[_vm._v(" At ALY HOME, we focus on trust, efficiency and quality. We guarantee our clients a fast turnaround on a top quality product, while our online portal makes ordering as simple as possible. ")]),_c('div',{staticClass:"content-icons"},[_c('img',{staticClass:"content-icon",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/from@2x.png"}}),_c('img',{staticClass:"content-icon",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/single@2x.png"}}),_c('img',{staticClass:"content-icon",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fast@2x.png"}}),_c('img',{staticClass:"content-icon",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/year@2x.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"store-item store-bgc2"},[_c('div',{staticClass:"store-text-part"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Where It All Happens")])]),_c('div',{staticClass:"content-text"},[_vm._v(" Our home consists of some large facilities located in Ningbo, China. Here, we manufacture and send all orders. We like our factory location, combined with stylish and modern workspaces and the latest technology. ")])]),_c('img',{staticClass:"store-img-part",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/part6/sto_bg_loc@2x.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-copy",staticStyle:{"background-color":"#caa68a"}},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Our Services")])]),_c('div',{staticClass:"content-text"},[_vm._v(" Quality customer service is of paramount importance to us. Our online portal makes ordering as simple as possible, while our customer service specialists are always on hand to help. Our samples department also plays a key role, offering customers a fast, free sampling service. Finally, we only partner with trusted couriers, ensuring your products are delivered safely and efficiently. ")]),_c('div',{staticClass:"pro-content-imgs"},[_c('img',{staticClass:"pro-content-img",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/from@2x.png"}}),_c('img',{staticClass:"pro-content-img",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/single@2x.png"}}),_c('img',{staticClass:"pro-content-img",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fast@2x.png"}}),_c('img',{staticClass:"pro-content-img",attrs:{"src":"https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/year@2x.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-copy"},[_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-liney"}),_c('div',{staticClass:"content-title"},[_vm._v("Our Responsibility")])]),_c('div',{staticClass:"content-text"},[_vm._v(" ALY HOME is an independent company that cares. Our wooden blinds are made from 100% real wood, harvested from forests that are responsibly managed for sustainability. We are also dedicated to practising environmentally-friendly manufacturing techniques, as well as recycling, reusing and donating where possible. ")])])
}]

export { render, staticRenderFns }